<template>
  <div :class="Switch ? 'goods_lengthways' : 'goods_item_box'">
    <div class="box">
      <div
        class="img_box"
        @click="
          $router.push({
            path: '/goodsDetails',
            query: {
              barcode: goodsInfo.barcode,
              enterpriseId: goodsInfo.enterpriseId,
            },
          })
        "
      >
        <img v-lazy="goodsInfo.filePath" alt="" />
        <span class="new_product" v-if="goodsInfo.newProducts">新品</span>
        <span class="seckill" v-if="goodsStatus === 2">秒杀</span>
        <div class="preferential" v-if="goodsStatus === 4">
          <img src="@/assets/images/tj.png" alt="" />
          <span>已省{{ preferentialPrice }}元</span>
        </div>
      </div>
      <ul>
        <li class="name">{{ goodsInfo.commodityName }}</li>
        <li class="enterprise_name" v-if="goodsInfo.storeName">
          <svg-icon icon-class="store_icon" />
          {{ goodsInfo.storeName }}
        </li>
        <li class="price">
          <span class="left">
            <i>￥</i>
            <b v-if="goodsStatus === 1">
              {{ goodsInfo.entity.price.toFixed(2) }}
            </b>
            <b v-else-if="goodsStatus === 2">
              {{
                goodsInfo.querySecKillCommodityListRsp.retailSeckillPrice.toFixed(
                  2
                )
              }}
            </b>
            <b v-else-if="userType === 'PURCHASE'"> {{ tradePrice }}</b>
            <b v-else>{{ retailPrice }}</b>
            <i> /{{ goodsInfo.prickle }}</i>
          </span>
          <van-button
            class="specification"
            color="linear-gradient(180deg, #F91C12 0%, #FE6028 100%)"
            round
            size="mini"
            v-if="
              (goodsStatus === 0 || goodsStatus === 4) &&
              goodsInfo.storeCommodityAttributeEntities
                ? goodsInfo.storeCommodityAttributeEntities.length
                : false
            "
            @click="buyShow = true"
            >选规格</van-button
          >
          <div
            class="stepper"
            v-else-if="goodsStatus !== 1 && goodsStatus !== 2"
          >
            <div>
              <svg-icon
                icon-class="home_jg_button2_icon"
                @click.stop="updataQuantity(0)"
                :class="goodsInfo.quantity ? 'show' : ''"
              />
            </div>
            <span
              :style="
                goodsInfo.quantity ? 'opacity:1' : 'opacity:0;transition: none;'
              "
              >{{ goodsInfo.quantity > 99 ? "99" : goodsInfo.quantity }}</span
            >
            <svg-icon
              icon-class="home_jg_button_icon"
              @click.stop="
                goodsInfo.quantity ? updataQuantity(1) : onAddCartClicked()
              "
            />
          </div>
          <!-- <span>{{ goodsInfo.province | city }}{{ goodsInfo.city | city }}</span> -->
        </li>
      </ul>
    </div>
    <!-- 商品规格选择弹出窗 -->
    <van-action-sheet
      v-if="goodsInfo.multiAttribute && goodsStatus !== 1 && goodsStatus !== 2"
      v-model="buyShow"
      :safe-area-inset-bottom="false"
      class="buy_show"
      @open="openShow"
      @closed="closeShow"
      get-container="#app"
    >
      <div class="content">
        <div class="top">
          <img :src="seleteImg ? seleteImg : goodsInfo.filePath" alt="" />
          <div class="price">
            <b v-if="goodsStatus === 1">
              {{ goodsInfo.entity.price.toFixed(2) }}
            </b>
            <b v-else-if="userType === 'PURCHASE'"
              ><span>￥</span>{{ tradePrice }}</b
            >
            <b v-else><span>￥</span>{{ retailPrice }}</b>
            <p>已选,{{ quantity }}{{ goodsInfo.prickle }}</p>
          </div>
          <svg-icon icon-class="close" @click="buyShow = false" />
        </div>
        <div class="Sku">
          <div class="specification">
            <div
              v-for="(list, index) in goodsInfo.storeCommodityAttributeEntities"
              :key="index"
            >
              <p>{{ list.attributeName }}</p>
              <ul>
                <li
                  v-for="(item, idx) in list.attributeValue"
                  :key="idx"
                  :style="
                    list.index === idx ? 'background:#009A4D;color:#fff' : ''
                  "
                  @click="selectAttribute(list, idx)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <div class="quantity">
            <p>购买数量</p>
            <span v-if="goodsStatus === 1">限量1件</span>
            <van-stepper
              v-else-if="userType === 'PURCHASE'"
              v-model="quantity"
              :min="
                goodsInfo.commodityPriceDetailsRsp.conditionSetting == 2
                  ? goodsInfo.commodityPriceDetailsRsp.tradeMinimumQuantity
                  : 1
              "
              :step="
                goodsInfo.commodityPriceDetailsRsp.multiple
                  ? goodsInfo.commodityPriceDetailsRsp.tradeMinimumQuantity
                  : 1
              "
            />
            <van-stepper v-else v-model="quantity" :min="1" />
            <p
              class="condition"
              v-if="
                userType === 'PURCHASE' &&
                goodsInfo.commodityPriceDetailsRsp.conditionSetting == 2
              "
            >
              (起订量≥{{
                goodsInfo.commodityPriceDetailsRsp.tradeMinimumQuantity
              }}{{ goodsInfo.commodityPriceDetailsRsp.prickle
              }}{{
                goodsInfo.commodityPriceDetailsRsp.multiple ? ",整倍订货" : ""
              }})
            </p>
            <p
              class="condition"
              v-if="
                userType === 'PURCHASE' &&
                goodsInfo.commodityPriceDetailsRsp.conditionSetting == 1
              "
            >
              (起订价≥￥{{
                goodsInfo.commodityPriceDetailsRsp.tradeStartingPrice | Price
              }})
            </p>
          </div>
        </div>
        <van-goods-action>
          <van-goods-action-button
            color="#F3AD3D"
            text="加入购物车"
            @click="onAddCartClicked2" />
          <van-goods-action-button
            color="#F91C12"
            text="立即购买"
            @click="onBuyClicked"
        /></van-goods-action>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { preferential, btoa } from "@/utils/validate";

export default {
  name: "goods",
  props: ["goodsInfo", "Switch", "storeInfo"],
  computed: {
    ...mapState("user", ["userType", "userInfo", "isLogin", "tzInfo"]),
    tradePrice() {
      let preferentialInfo = preferential(this.goodsInfo, "tradePrice");
      this.preferentialPrice = preferentialInfo.preferentialPrice;
      if (preferentialInfo.state && !this.goodsStatus) this.goodsStatus = 4;
      return preferentialInfo.price;
    },
    retailPrice() {
      let preferentialInfo = preferential(this.goodsInfo, "retailPrice");
      this.preferentialPrice = preferentialInfo.preferentialPrice;
      if (preferentialInfo.state && !this.goodsStatus) this.goodsStatus = 4;
      return preferentialInfo.price;
    },
  },
  data() {
    return {
      id: "",
      goodsStatus: 0, //0无特殊,1新人尝鲜,2限时秒杀,3-直播价,4-特惠价
      preferentialPrice: 0,
      quantity: 1,
      buyShow: false,
      seleteImg: "",
    };
  },

  filters: {
    city(city) {
      if (city) {
        return city.split("省")[0].split("市")[0];
      } else {
        return "";
      }
    },
  },
  created() {
    if (
      this.goodsInfo.newUser &&
      this.goodsInfo.entity &&
      this.goodsInfo.querySecKillCommodityListRsp &&
      this.goodsInfo.querySecKillCommodityListRsp.seckillEnableState === 1
    ) {
      if (
        this.goodsInfo.entity.price <=
        this.goodsInfo.querySecKillCommodityListRsp.retailSeckillPrice
      ) {
        this.goodsStatus = 1;
      } else {
        this.goodsStatus = 2;
      }
    } else if (this.goodsInfo.newUser && this.goodsInfo.entity) {
      this.goodsStatus = 1;
    } else if (
      this.goodsInfo.querySecKillCommodityListRsp &&
      this.goodsInfo.querySecKillCommodityListRsp.seckillEnableState === 1
    ) {
      this.goodsStatus = 2;
    }
  },
  methods: {
    //加入购物车
    async onAddCartClicked() {
      if (!this.isLogin) {
        this.$router.push({
          path: "/login",
          query: {
            redirect_uri: this.$route.fullPath,
          },
        });
        return;
      }
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.goodsInfo.quantity++;

        await this.$API.order
          .InsertCusOrderCart({
            object: {
              attributeId: [],
              barcode: this.goodsInfo.barcode,
              commId: this.goodsInfo.id,
              type: this.goodsStatus,
              typeId: "",
              quantity: this.goodsInfo.quantity,
              storeId: this.goodsInfo.storeId
                ? this.goodsInfo.storeId
                : this.storeInfo.storeId,
              userId: this.userInfo.id,
            },
          })
          .then((res) => {
            if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
            this.id = res.data.data;
            this.$store.dispatch("user/getQuantity");
          })
          .catch((e) => {
            this.Toast.fail(e.message);
            this.goodsInfo.quantity = 0;
          });
      }, 500);
    },
    //修改商品数量
    updataQuantity(i) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        if (i) {
          //+
          this.goodsInfo.quantity++;
        } else {
          //-
          this.goodsInfo.quantity > 0 && this.goodsInfo.quantity--;
        }

        if (this.goodsInfo.quantity < 1) {
          await this.deleteGoods();
          return;
        }
        await this.$API.order.UpdateOrderCart({
          object: {
            attributeId: "",
            id: this.id ? this.id : this.goodsInfo.orderCartListItems[0].id,
            quantity: this.goodsInfo.quantity,
          },
        });
        this.$store.dispatch("user/getQuantity");
      }, 300);
    },
    //删除购物车商品
    async deleteGoods() {
      await this.$API.order
        .DeleteOrderCart({
          object: {
            id: this.id ? [this.id] : [this.goodsInfo.orderCartListItems[0].id],
          },
        })
        .then(async (res) => {
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          this.$store.dispatch("user/getQuantity");
        })
        .catch((err) => {
          this.Toast.fail(err.message);
        });
    },
    //加入购物车
    async onAddCartClicked2() {
      if (!this.isLogin) {
        this.$router.push({
          path: "/login",
          query: {
            redirect_uri: this.$route.fullPath,
          },
        });
        return;
      }
      let attributeId = [];
      if (this.goodsInfo.storeCommodityAttributeEntities) {
        try {
          this.goodsInfo.storeCommodityAttributeEntities.forEach((item) => {
            if (item.index === -1) {
              throw Error(`请选择商品${item.attributeName}`);
            }
            attributeId.push(item.id[item.index]);
          });
        } catch (e) {
          this.Toast.fail({
            message: e.message,
            className: "buy_show_tips",
          });
          return;
        }
      }

      await this.$API.order
        .InsertCusOrderCart({
          object: {
            attributeId: attributeId,
            barcode: this.goodsInfo.barcode,
            commId: this.goodsInfo.id,
            type: this.goodsStatus,
            typeId: this.goodsStatus === 1 ? this.goodsInfo.entity.id : "",
            quantity: this.quantity,
            storeId: this.goodsInfo.storeId
              ? this.goodsInfo.storeId
              : this.storeInfo.storeId,
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            this.$store.dispatch("user/getQuantity");
            this.buyShow = false;
            this.quantity = 1;
            this.Toast.success("添加购物车成功");
          } else {
            this.Toast.fail(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //立即购买
    onBuyClicked() {
      this.Toast.fail({
        message: "因商城页面调整，暂不支持下单，请查看公告。",
        className: "buy_show_tips",
      });
      return;

      if (!this.isLogin) {
        this.$router.push({
          path: "/login",
          query: {
            redirect_uri: this.$route.fullPath,
          },
        });
        return;
      }
      if (this.userType === "PURCHASE") {
        this.Toast.fail({
          message: "  店长请使用  PDA终端开展店长业务。",
          className: "buy_show_tips",
        });
      } else {
        if (this.tzInfo === null) {
          this.Toast.fail({
            message:
              "您当前使用的不是店长的二维码或链接，请联系店长获取二维码或链接",
            className: "buy_show_tips",
          });

          return;
        }
        this.Toast.loading({
          duration: 0,
          message: "加载中...",
          forbidClick: true,
          className: "goods_loading",
        });
        //商品规格选择
        let attributeId = [];
        let attributeName = [];
        if (this.goodsInfo.storeCommodityAttributeEntities) {
          try {
            this.goodsInfo.storeCommodityAttributeEntities.forEach((item) => {
              if (item.index === -1) {
                throw Error(`请选择商品${item.attributeName}`);
              }
              attributeName.push(item.attributeValue[item.index]);
              attributeId.push(item.id[item.index]);
            });
          } catch (e) {
            this.Toast.fail({
              message: e.message,
              className: "buy_show_tips",
            });
            return;
          }
        }

        let price;
        let typeId;
        let deduction;
        let freeShipping;
        if (this.goodsStatus === 1) {
          //新人
          typeId = this.goodsInfo.entity.id;
          price = this.goodsInfo.entity.price;
          deduction = this.goodsInfo.entity.deduction;
          freeShipping = this.goodsInfo.entity.freeShipping;
        } else {
          //正常
          price = this.retailPrice;
          typeId = "";
          deduction = this.goodsInfo.commodityPriceDetailsRsp.deduction;
          freeShipping = this.goodsInfo.commodityPriceDetailsRsp.freeShipping;
        }

        let order = [
          {
            storeId: this.goodsInfo.storeId
              ? this.goodsInfo.storeId
              : this.storeInfo.storeId,
            storeName: this.goodsInfo.storeName
              ? this.goodsInfo.storeName
              : this.storeInfo.storeName,
            amount: Math.round(price * this.quantity * 100) / 100,
            freeShipping: freeShipping,
            message: "",
            orderCardListItems: [
              {
                ...this.goodsInfo,
                ...this.goodsInfo.commodityPriceDetailsRsp,
                deduction: deduction,
                attributeId: attributeId.join(","),
                attributeName: attributeName.join("/"),
                quantity: this.quantity,
                retailPrice: Number(price),
                tradePrice: this.tradePrice,
                commId: Number(this.goodsInfo.id),
                filePath: this.goodsInfo.filePath,
                type: this.goodsStatus,
                typeId: typeId,
              },
            ],
          },
        ];

        setTimeout(() => {
          this.Toast.clear();
          sessionStorage.setItem(window.btoa("order"), btoa(order, 1));
          this.$router.push("/confirmAnOrder");
        }, 500);
      }
    },
    openShow() {
      if (this.goodsInfo.storeCommodityAttributeEntities) {
        this.goodsInfo.storeCommodityAttributeEntities.forEach((item) => {
          this.$set(item, "index", -1);

          if (!Array.isArray(item.attributeValue))
            item.attributeValue = item.attributeValue.split(",");
          if (!Array.isArray(item.filePath))
            item.filePath = item.filePath.split(",");
          if (!Array.isArray(item.id)) item.id = item.id.split(",");
        });
      }
    },
    //商品规格弹窗关闭事件
    closeShow() {
      if (this.goodsInfo.storeCommodityAttributeEntities) {
        this.goodsInfo.storeCommodityAttributeEntities.forEach((goods) => {
          goods.index = -1;
        });
        this.seleteImg = "";
      }
    },
    //选择商品sku
    selectAttribute(item, i) {
      if (item.index === -1 || item.index !== i) {
        item.index = i;
        if (JSON.parse(item.defaultFile.split(",")[0]))
          this.seleteImg = item.filePath[i];
      } else {
        item.index = -1;
        if (JSON.parse(item.defaultFile.split(",")[0])) this.seleteImg = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.goods_item_box {
  // width: 50%;
  width: 48.5%;
  display: inline-block;
  // height: 250px;
  position: absolute;

  background: #fff;
  border-radius: 10px;

  .box {
    background: #fff;
    border-radius: 10px;
    .img_box {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%;
      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0 0;
        object-fit: contain;
      }
      .new_product {
        position: absolute;
        top: 8px;
        left: 0;
        background: linear-gradient(180deg, #2d87ff 0%, #0f63d5 100%);
        border-radius: 2px;
        font-size: 12px;
        color: #fff;
        padding: 4px 7px;
      }
      .seckill {
        position: absolute;
        right: 0;
        top: 0;
        background: url("../../assets/images/home_ms_bg.png") no-repeat 100%
          100%;
        background-size: 100%;
        font-size: 12px;
        color: #ffffff;
        line-height: 17px;
        padding: 20px 8px 13px;
      }
      .preferential {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        // height: 43px;

        img {
          width: 100%;
          display: block;
        }
        span {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 70%;
          width: 65%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: bold;
          color: #fefa9c;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      padding: 12px 8px;
      // height: 82px;
      // li {
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // }
      .name {
        font-weight: bold;
        color: #333333;
        line-height: 20px;
        text-align: start;
        margin-bottom: 9px;
      }
      .price {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // flex-wrap: wrap;
        .left {
          float: left;
          font-size: 18px;
          color: #f91c12;
          // margin-left: -5px;
          float: left;
          line-height: 24px;
          i {
            font-size: 12px;
            font-style: normal;
            display: inline-block;
          }
          b {
            margin-right: 1px;
          }
        }
        ::v-deep .specification {
          float: right;
          padding: 0 6px;
          .van-button__text {
            color: #fff;
            font-size: 12px;
            line-height: 24px;
          }
        }
        // span:nth-child(2) {
        //   font-size: 12px;
        //   color: #999999;
        //   float: right;
        //   width: 50px;
        //   text-align: end;
        //   white-space: nowrap;
        //   text-overflow: ellipsis;
        //   overflow: hidden;
        // }
        .stepper {
          float: right;
          display: flex;
          align-items: center;
          position: relative;
          overflow: hidden;
          margin-left: 6px;
          height: 22px;
          // width: 64px;
          .svg-icon {
            font-size: 22px;
          }
          span {
            transition: 0.2s all;
            width: 22px;
            text-align: center;
          }
          > div {
            width: 22px;
          }
          .svg-icon:nth-child(1) {
            position: absolute;
            top: 0;
            left: 80px;
            transition: 0.5s all;
            transform: rotate(540deg);
            opacity: 0;
          }
          .show {
            left: 0 !important;
            transform: rotate(0) !important;
            opacity: 1 !important;
          }
        }
      }
      .enterprise_name {
        font-size: 12px;
        line-height: 16px;
        text-align: start;
        color: #666666;
        margin-bottom: 15px;
        .svg-icon {
          margin-right: 2px;
        }
      }
    }
  }
}
.goods_lengthways {
  background: #fff;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 10px;
  .box {
    display: flex;
    .img_box {
      position: relative;
      > img {
        width: 135px;
        height: 135px;
        border-radius: 5px;
        display: block;
      }
      .new_product {
        position: absolute;
        top: 8px;
        left: 0;
        background: linear-gradient(180deg, #2d87ff 0%, #0f63d5 100%);
        border-radius: 2px;
        font-size: 12px;
        color: #fff;
        padding: 4px 7px;
      }
      .seckill {
        position: absolute;
        right: 0;
        top: 0;
        background: url("../../assets/images/home_ms_bg.png") no-repeat 100%
          100%;
        background-size: 100%;
        font-size: 12px;
        color: #ffffff;
        line-height: 17px;
        padding: 20px 8px 13px;
      }
      .preferential {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        // height: 43px;

        img {
          width: 100%;
          display: block;
        }
        span {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 70%;
          width: 65%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: bold;
          color: #fefa9c;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      padding: 0 10px;
      // li:nth-child(1) {
      //   height: 40px;
      // }
      li {
        // width: 192px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 20px;
      }

      .price {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // flex-wrap: wrap;
        .left {
          font-size: 18px;
          color: #f91c12;
          // margin-left: -5px;
          line-height: 24px;
          i {
            font-size: 12px;
            font-style: normal;
            display: inline-block;
          }
          b {
            margin-right: 1px;
          }
        }
        // span:nth-child(2) {
        //   font-size: 12px;
        //   color: #999999;
        //   float: right;
        //   width: 50px;
        //   text-align: end;
        //   white-space: nowrap;
        //   text-overflow: ellipsis;
        //   overflow: hidden;
        // }
        ::v-deep .specification {
          float: right;
          padding: 0 6px;
          .van-button__text {
            color: #fff;
            font-size: 12px;
          }
        }
        .stepper {
          float: right;
          display: flex;
          align-items: center;
          position: relative;
          overflow: hidden;
          margin-left: 6px;

          .svg-icon {
            font-size: 22px;
          }
          span {
            transition: 0.2s all;
            padding: 0 5px;
          }
          > div {
            width: 22px;
          }
          .svg-icon:nth-child(1) {
            position: absolute;
            top: 0;
            left: 80px;
            transition: 0.5s all;
            transform: rotate(540deg);
            opacity: 0;
          }
          .show {
            left: 0 !important;
            transform: rotate(0) !important;
            opacity: 1 !important;
          }
        }
      }
      .enterprise_name {
        font-size: 12px;
        line-height: 16px;
        color: #666666;
        .svg-icon {
          margin-right: 5px;
        }
      }
    }
  }
}
.goods_lengthways:last-child {
  margin-bottom: 0;
}
.buy_show {
  overflow: unset;
  z-index: 10000 !important;
  // height: 60%;
  .van-action-sheet__content {
    overflow: unset;
  }
  .content {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      position: relative;
      top: -16px;
      z-index: 9999;
      padding: 0 15px;
      img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        margin-right: 12px;
      }
      .price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        b {
          span {
            font-size: 12px;
            font-style: normal;
            display: inline-block;
            transform: scale(0.8);
            -webkit-transform: scale(0.8);
            -moz-transform: scaleY(0.8);
            position: relative;
            left: 2px;
            top: 1px;
            z-index: 0;
          }
          margin-left: -4px;
          font-size: 16px;
          line-height: 24px;
          color: #fb4e10;
        }
        p {
          line-height: 20px;
          color: #333333;
        }
      }
      .svg-icon {
        position: absolute;
        top: 39px;
        right: 15px;
        color: #868686;
      }
    }
    .Sku {
      height: 30vh;
      overflow-y: scroll;
      padding: 0 15px;
      margin-bottom: 15px;
      .specification {
        > div {
          p {
            margin-bottom: 6px;
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              margin-bottom: 12px;
              transition: 0.2s all;
              line-height: 26px;
              background: #f5f5f5;
              border-radius: 13px;
              padding: 0 10px;
              color: #666;
              margin-right: 10px;
            }
          }
        }
      }
      .quantity {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .van-goods-action {
    padding: 0 15px;
    position: unset;
    height: 34px;
    flex: 1;
    .van-button {
      margin: 0;
      height: 34px;
      font-size: 16px;
    }
  }
}
</style>

<style lang="scss">
.goods_loading {
  z-index: 100001 !important;
}
.buy_show_tips {
  z-index: 10001 !important;
}
</style>
